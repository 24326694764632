<template>
  <a
    class="footer-btn pt-3 mt-16"
    :href="href"
    target="_blank"
  >
    <TTView
      class="pt-0"
    >
      <div class="d-flex align-center flex-gap">
        <div class="d-flex flex-column">
          <VImg
            :src="require('@/assets/img/potok-logo.svg')"
            class="logo"
            max-width="110"
          />
          <div
            v-if="$vuetify.breakpoint.smAndDown"
            class="text-caption tt-black--text text--lighten-2 text-no-wrap mobile-copyright"
          >
            {{ $t('footerCopyright', { year }) }}
          </div>
        </div>
        <div
          :class="$vuetify.breakpoint.smAndDown && 'text-right mt-1'"
          class="flex-grow-1 text-body-2"
        >
          <div class="d-inline-block text-left">
            {{ $t('footerMessage') }}
          </div>
        </div>
        <div
          v-if="!$vuetify.breakpoint.smAndDown"
          class="text-caption tt-black--text text--lighten-1"
        >
          {{ $t('footerCopyright', { year }) }}
        </div>
      </div>
    </TTView>
  </a>
</template>

<script>
import TTView from '@/components/TTView.vue';

export default {
  name: 'AppInfo',
  components: {
    TTView,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      href: 'https://potok.io/survey/?utm_source=survey_completing&utm_content=product_oprosy',
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-btn{
  padding-top: 2px;
  text-decoration: none;
  color: map-get($tt-black, 'lighten-1');
  border-top: 1px solid map-get($tt-black, 'lighten-4');
}
.footer-btn:hover {
  color: map-get($tt-blue, 'lighten-1');
  background-color: map-get($tt-blue, 'lighten-3');
}
.flex-gap {
  gap: 36px;
}
.logo {
  margin-bottom: 1px;
}
.mobile-copyright {
  margin-top: -8px;
  margin-left: 2px;
}
</style>
